import React from "react";
import Footer from "../../components/footer/footer";
import CustomContainer from "../../components/general/contianer"
import SEO from "../../components/seo/seo"
import InlineLink from "../../components/general/inline-link";

const FAQIndex = ({transitionStatus}) => {

    return (
        <>
            <SEO
                description={""}
                title={"Contact"}
                pathname={"/contact"}
            />
            <CustomContainer>
                <h1 className={"mt-40 text-gray-800 font-bold font-serif text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4"}>
                    We're here for all your questions.
                </h1>
                {/*<div style={{ textAlign: "center" }}>*/}
                {/*    <div style={{ height: xsDown ? "4px" : "12px" }}/>*/}
                {/*    <Text variant={'body2'} light>*/}
                {/*        Contact us <Text variant={'body2'} medium component={'span'}>7 days a week</Text> for any queries.*/}
                {/*    </Text>*/}
                {/*    <div style={{ height: "32px" }}/>*/}
                {/*    <SecondaryButton disableArrowAnimation style={{ height: "48px", width: "220px"}} to={"mailto:care@groomd.co.in"} >*/}
                {/*        Send Email*/}
                {/*    </SecondaryButton>*/}
                {/*</div>*/}
                <div style={{ height: "80px" }}/>
                <div className={"grid grid-cols-1 sm:grid-cols-3 font-sans "}>
                    <div>
                        <div>
                            <p className={"font-medium text-xl"} style={{ maxWidth: "250px" }}>
                                Contact us 7 days a week for any queries.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className={"opacity-75 text-sm"}>
                                Email
                            </p>
                            <p >
                                <InlineLink variant={"secondary"} to={"mailto:care@groomd.co.in"}>
                                    care@groomd.co.in
                                </InlineLink>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div style={{ boxSizing: "border-box" }}>
                            <p className={"opacity-75 text-sm"}>
                                Address
                            </p>
                            <p >
                                Plot No 2A, First Floor, KH No 294,
                                <br/>Kehar Singh State, Saidulajab Village, Lane No 2,
                                <br/>New Delhi - 110030
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{ height: "80px" }}/>
            </CustomContainer>
            <Footer transitionStatus={transitionStatus}/>
        </>
    )
}

export default FAQIndex;